import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Website currently under construction
        </p>
        <p>
          Please reach out directly to <a href="mailto:ross@mawtechnologies.com">ross@mawtechnologies.com</a> for inquiries
        </p>
      </header>
    </div>
  );
}

export default App;
